:root {
  --background-color: rgb(51, 102, 153);
  --background-gradient: radial-gradient(
    circle,
    rgba(51, 102, 153, 1) 0%,
    rgba(102, 147, 202, 1) 50%,
    rgba(0, 60, 107, 1) 100%
  );
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  //background: var(--background-color) no-repeat;
  //background: var(--background-gradient);
  //background-repeat: no-repeat;
  //background-attachment: fixed;
  /* Hintergrundfarbe mit Transparenz */
  background: rgba(51, 102, 153, 0.6) !important;
  /* Hintergrundunschärfe */
  backdrop-filter: blur(10px);
  //text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.app {
  &-home {
    height: 100%;
  }

  &-footer {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: #336699;
    color: white;

    a {
      color: white;
    }
  }
}
